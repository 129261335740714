import { CheckinVisitorDto, CheckinVisitorEventDto } from "@smallstack/axios-api-client";

export const ACTION_SCANBARCODE = "scanBarcode";
export const RESPONSE_SCANBARCODE_SUCCESS = "scanBarcodeSuccess";
export const RESPONSE_SCANBARCODE_ERROR = "scanBarcodeError";

export const ACTION_PRINT = "print";
export const RESPONSE_PRINT_SUCCESS = "printSuccess";
export const RESPONSE_PRINT_ERROR = "printError";

export const ACTION_PRINT_FORM = "printForm";
export const RESPONSE_PRINT_FORM_SUCCESS = "printFormSuccess";
export const RESPONSE_PRINT_FORM_ERROR = "printFormError";

const nsBridgewindowPropertyName: string = "nsWebViewBridge";

/**
 * Bridge between native "Stage App" and this PWA
 */
export class ContentStageBridge {
  public isAvailable(): boolean {
    return (window as any)[nsBridgewindowPropertyName] !== undefined;
  }

  public async scanBarcode(): Promise<string> {
    if (!this.isAvailable()) throw new Error("Nativescript Bridge not available!");
    return new Promise<string>((resolve, reject) => {
      (window as any)[nsBridgewindowPropertyName].on(RESPONSE_SCANBARCODE_SUCCESS, (data: string) => {
        resolve(data);
        this.unregisterEvents(RESPONSE_SCANBARCODE_SUCCESS, RESPONSE_SCANBARCODE_ERROR);
      });
      (window as any)[nsBridgewindowPropertyName].on(RESPONSE_SCANBARCODE_ERROR, (error: Error) => {
        reject(error);
        this.unregisterEvents(RESPONSE_SCANBARCODE_SUCCESS, RESPONSE_SCANBARCODE_ERROR);
      });
      (window as any)[nsBridgewindowPropertyName].emit(ACTION_SCANBARCODE);
    });
  }

  public async printVisitor(visitor: CheckinVisitorDto, event?: CheckinVisitorEventDto): Promise<string> {
    if (!this.isAvailable()) throw new Error("Nativescript Bridge not available!");
    return new Promise<string>((resolve, reject) => {
      (window as any)[nsBridgewindowPropertyName].on(RESPONSE_PRINT_SUCCESS, (res: string) => {
        resolve(res);
        this.unregisterEvents(RESPONSE_PRINT_SUCCESS, RESPONSE_PRINT_ERROR);
      });
      (window as any)[nsBridgewindowPropertyName].on(RESPONSE_PRINT_ERROR, (error: Error) => {
        reject(error);
        this.unregisterEvents(RESPONSE_PRINT_SUCCESS, RESPONSE_PRINT_ERROR);
      });
      (window as any)[nsBridgewindowPropertyName].on(RESPONSE_PRINT_FORM_SUCCESS, (res: string) => {
        resolve(res);
        this.unregisterEvents(RESPONSE_PRINT_FORM_SUCCESS, RESPONSE_PRINT_FORM_ERROR);
      });
      (window as any)[nsBridgewindowPropertyName].on(RESPONSE_PRINT_FORM_ERROR, (error: Error) => {
        reject(error);
        this.unregisterEvents(RESPONSE_PRINT_FORM_SUCCESS, RESPONSE_PRINT_FORM_ERROR);
      });
      const data = visitor.fields;
      data.USER_ID = visitor.id;
      (window as any)[nsBridgewindowPropertyName].emit(ACTION_PRINT_FORM, { formId: event?.formId, content: data });
    });
  }

  private unregisterEvents(...events: string[]) {
    events.forEach((e) => (window as any)[nsBridgewindowPropertyName].off(e));
  }
}
