import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CheckinVisitorEventDto } from "@smallstack/axios-api-client";
import { CheckinService } from "../../../shared/checkin.service";
import { CheckinAppEvent, VisitorApp } from "../../../shared/types";

@Component({
  selector: "visitor-events",
  templateUrl: "./visitor-events.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisitorEventsComponent implements OnInit {
  @Input()
  public app: VisitorApp;

  @Input()
  public visitorId: string;

  @Input()
  public lastVisitEvent: CheckinVisitorEventDto;

  @Input()
  public btnClass: string = "checkin-button";

  public loading = false;

  @Output()
  public readonly newEventCreated: EventEmitter<void> = new EventEmitter();

  public applicableEvents: CheckinAppEvent[];

  constructor(private checkinService: CheckinService) {}

  public ngOnInit(): void {
    if (this.app) {
      if (!this.app.events) this.applicableEvents = undefined;
      else {
        const publicEvents = this.app.events.filter((event) => event.public);
        if (!this.lastVisitEvent) this.applicableEvents = publicEvents;
        else this.applicableEvents = publicEvents.filter((event) => event.name !== this.lastVisitEvent.type);
      }
    }
  }

  public showEventButton(eventName: string): boolean {
    return this.lastVisitEvent?.type !== eventName;
  }

  public async changeActiveStatus(event: string, print: boolean): Promise<void> {
    this.loading = true;
    await this.checkinService.createVisitEvent(this.visitorId, event as any);
    this.newEventCreated.emit();
    this.loading = false;
  }
}
