@if (loading) {
  <smallstack-loader loadingText="" />
} @else {
  @if (applicableEvents) {
    <div class="flex flex-col space-y-3">
      @for (event of applicableEvents; track event) {
        @if (showEventButton(event.name)) {
          <button
            data-test="visitor-event-btn"
            class="h-full w-full min-h-full min-w-full btn btn-outline btn-primary"
            [ngClass]="btnClass"
            (click)="changeActiveStatus(event.name, false)"
            >{{ event.label }}</button
          >
        }
      }
    </div>
  } @else {
    <div class="justify-center">
      <small><i>Derzeit stehen keine Aktionen verfügbar.</i></small>
    </div>
  }
}
