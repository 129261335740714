import { HttpClient } from "@angular/common/http";
import { HostListener, Injectable } from "@angular/core";
import { ActivationEnd, Router } from "@angular/router";
import { AxiosApiClient, CheckinVisitorDto, CheckinVisitorEventDto, TenantDataApi } from "@smallstack/axios-api-client";
import { CheckinService as BaseCheckinService } from "@smallstack/checkin-client";
import { ContentStageBridge } from "@smallstack/content-stage-shared";
import { Form } from "@smallstack/form-shared";
import { LocaleService } from "@smallstack/i18n-components";
import { Store } from "@smallstack/store";
import { F_CHECKIN, F_FORM, TYPE_CHECKIN_APPS, TYPE_FORMS, T_CHECKIN_VISITOR } from "@smallstack/typesystem";
import { BehaviorSubject } from "rxjs";
import { filter, first, tap } from "rxjs/operators";
import { ErrorNoInternet, ErrorNotAvailable, ErrorUnknownError } from "./messages";
import { HandleErrorDialog, PreviewData, SimpleDialogPageState, VisitorApp, concatAndResolveUrl } from "./types";

@Injectable({ providedIn: "root" })
export class CheckinService extends BaseCheckinService {
  public currentApp$: Store<VisitorApp> = new Store<VisitorApp>(undefined);
  public currentForms$: BehaviorSubject<Form[]> = new BehaviorSubject<Form[]>(undefined);
  public currentForm$: BehaviorSubject<Form> = new BehaviorSubject<Form>(undefined);

  private currentAppId: string;
  private currentFormId: string;
  private locale: string = this.localeService.currentLocale$.value;

  constructor(
    private router: Router,
    axiosApiClient: AxiosApiClient,
    private stageBridge: ContentStageBridge,
    http: HttpClient,
    private localeService: LocaleService
  ) {
    super(axiosApiClient, http);

    void this.localeService.currentLocale$.pipe(filter((l) => l !== undefined)).subscribe((locale) => {
      if (locale !== this.locale) {
        this.locale = locale;
        void this.reload();
      }
    });

    void router.events.subscribe((event: any) => {
      if (event instanceof ActivationEnd) {
        this.currentAppId = event.snapshot.params.appId;
        this.currentFormId = event.snapshot.params.formId;
        void this.reload();
      }
    });
  }

  public async getVisitor(visitorId: string, ...handlers: HandleErrorDialog[]): Promise<CheckinVisitorDto> {
    try {
      return await this.axiosApiClient
        .get(TenantDataApi)
        .getDataById({ feature: F_CHECKIN, type: T_CHECKIN_VISITOR, id: visitorId })
        .then((res) => res.data as CheckinVisitorDto);
    } catch (e) {
      void this.handleApiError(e, ...handlers);
    }
  }

  public async printVisitorPass(visitor: CheckinVisitorDto, event: CheckinVisitorEventDto): Promise<void> {
    try {
      void this.stageBridge.printVisitor(visitor, event);
    } catch (e) {
      void this.handleApiError(e);
      throw e;
    }
  }

  @HostListener("window:message", ["$event"])
  public async onMessage(event: { data: unknown }): Promise<void> {
    const previewData = event.data as PreviewData;
    if (previewData && previewData.previewMode) {
      this.currentApp$.setValue(previewData.app);
      this.currentForms$.next(previewData.forms);
    }
  }

  private async reload() {
    if (this.currentAppId) {
      try {
        this.currentApp$.setLoading();
        const app: VisitorApp = await this.axiosApiClient
          .get(TenantDataApi)
          .getDataById({
            feature: F_CHECKIN,
            type: TYPE_CHECKIN_APPS,
            id: this.currentAppId,
            translate: this.locale
          })
          .then((res) => res.data as VisitorApp);
        if (app) {
          const forms: Form[] = await Promise.all(
            app.formIds.map((formId: string) =>
              this.axiosApiClient
                .get(TenantDataApi)
                .getDataById({ feature: F_FORM, type: TYPE_FORMS, id: formId, translate: this.locale })
                .then((res) => res.data as Form)
            )
          );
          this.currentForms$.next(forms);
          // default for validation error
          if (app.hasValidationErrorsText === undefined)
            app.hasValidationErrorsText = "Bitte füllen Sie die rot markierten Felder aus";
          this.currentApp$.setValue(app);
        } else
          this.currentApp$.setError(
            new Error("Die angegebene Check-In App mit der ID " + this.currentAppId + " wurde leider nicht gefunden!")
          );
      } catch (e) {
        this.currentApp$.setError(new Error(e));
      }
    } else this.currentApp$.setValue(undefined);
    if (this.currentFormId)
      void this.currentForms$
        .pipe(
          first(),
          tap((forms) => this.currentForm$.next(forms.find((f) => f.id === this.currentFormId)))
        )
        .subscribe();
    else this.currentForm$.next(undefined);
  }

  private handleApiError(reason: any, ...handlers: HandleErrorDialog[]) {
    // eslint-disable-next-line no-console
    console.error(reason);
    if (handlers) {
      for (const h of handlers) {
        if (h.when(reason)) {
          if (!h.dialog.okNavigate || h.dialog.okNavigate[0] !== "/") {
            h.dialog.okNavigate = concatAndResolveUrl(this.router.url, h.dialog.okNavigate);
          }
          return this.router.navigate(["/error"], {
            state: h.dialog
          });
        }
      }
    }
    if (!navigator.onLine) {
      return this.goToErrorPage(ErrorNoInternet);
    }
    if (reason.statusCode) {
      if (reason.statusCode === 404) {
        return this.goToErrorPage(ErrorNotAvailable);
      }
    }
    return this.goToErrorPage(ErrorUnknownError);
  }

  private async goToErrorPage(message: string) {
    const state: SimpleDialogPageState = {
      title: "",
      message,
      okNavigate: this.router.url
    };
    await this.router.navigate(["/error"], { state });
  }

  // private async toBuffer(base64: string): Promise<Blob> {
  //   return new Promise((resolve) => dataUrlToBlob(base64, (buffer) => {
  //     resolve(buffer);
  //   }));
  // }
}
