import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { LocaleService } from "@smallstack/i18n-components";
import { CheckinService } from "../../../shared/checkin.service";

@Component({
  selector: "app-footer",
  templateUrl: "./app-footer.component.html",
  styleUrls: ["./app-footer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppFooterComponent {
  public locales: string[];

  constructor(localeService: LocaleService, checkinService: CheckinService, cdr: ChangeDetectorRef) {
    checkinService.currentApp$.value$.subscribe(async (app) => {
      if (app) {
        const locales = await localeService.getAllLocales();
        await localeService.detectLocale();
        this.locales = locales.map((l) => l.iso);
      } else this.locales = [];
      cdr.markForCheck();
    });
  }
}
