import { ChangeDetectionStrategy, Component, Input, Renderer2 } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { VisitorApp } from "../../../shared/types";

@Component({
  selector: "app-header",
  templateUrl: "./app-header.component.html",
  styleUrls: ["./app-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppHeaderComponent {
  @Input() public backNavigate: string;

  public visitorApp: VisitorApp;

  @Input("app")
  set updateApp(app: VisitorApp) {
    this.visitorApp = app;
    if (app) {
      if (app.backgroundImageUrl) {
        this.renderer.setStyle(document.body, "background-image", 'url("' + app.backgroundImageUrl + '")');
        if (app.backgroundAlignment) {
          this.renderer.setStyle(document.body, "background-position", app.backgroundAlignment);
        }
      }

      if (app.logoWidth && app.logoWidth.includes("px") === false) app.logoWidth += "px";
      if (app.logoHeight && app.logoHeight.includes("px") === false) app.logoHeight += "px";
    }
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2
  ) {}

  public goBack() {
    void this.router.navigate([this.backNavigate], { relativeTo: this.activatedRoute });
  }
}
