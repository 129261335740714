import { Form } from "@smallstack/form-shared";

export interface SimpleDialogPageState {
  title: string;
  message: string;
  icon?: string;
  okNavigate?: string;
  timeout?: number;
}

export interface HandleErrorDialog {
  when: (error: { statusCode: number }) => boolean;
  dialog: SimpleDialogPageState;
}

export interface FormDocument {
  id: string;
  link: string;
  label: string;
  required: boolean;
}

export interface ContactPerson {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface CheckinAppEvent {
  name: string;
  label: string;
  public: boolean;
  isDefault?: boolean;
}

export interface VisitorApp {
  id: string;
  logoUrl: string;
  logoWidth: string;
  logoHeight: string;
  logoAlignment: string;
  backgroundImageUrl: string;
  backgroundAlignment: string;
  contentAlignment: string;
  title: string;
  message: string;
  successSignInTitle: string;
  successSignInMessage: string;
  successSignOutTitle: string;
  successSignOutMessage: string;
  signupTitle: string;
  signupMessage: string;
  signoutTitle: string;
  signoutMessage: string;
  formIds: string[];
  smallPrint: string;
  scannerActive: boolean;
  scanButtonHint: string;
  scanButtonText: string;
  formButtonHint: string;
  hasValidationErrorsText: string;
  deletionPeriods?: DeletionPeriod[];
  events?: CheckinAppEvent[];
}

export interface DeletionPeriod {
  /**
   * Duration in milliseconds
   */
  duration: number;
  label: string;
  isDefault?: boolean;
}

export interface CheckedDocument {
  checked: boolean;
  required: boolean;
}

export interface PreviewData {
  previewMode: boolean;
  app: VisitorApp;
  forms: Form[];
}

// WTF !!!!!!!!!!!!
export function concatAndResolveUrl(url: string, concat: string): string {
  const url1 = url ? url.split("/") : [];
  const url2 = concat ? concat.split("/") : [];
  const url3 = [];
  let i;
  for (i = 0; i < url1.length; i++) {
    if (url1[i] === "..") {
      url3.pop();
    } else if (url1[i] === ".") {
      continue;
    } else {
      url3.push(url1[i]);
    }
  }
  for (i = 0; i < url2.length; i++) {
    if (url2[i] === "..") {
      url3.pop();
    } else if (url2[i] === ".") {
      continue;
    } else {
      url3.push(url2[i]);
    }
  }
  return url3.join("/");
}
