import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SmallstackI18nModule } from "@smallstack/i18n-components";
import { VisitorStatusBadgeComponent } from "./components/visitor-status-badge/visitor-status-badge.component";

@NgModule({
  imports: [CommonModule, SmallstackI18nModule],
  declarations: [VisitorStatusBadgeComponent],
  exports: [VisitorStatusBadgeComponent]
})
export class SmallstackCheckinComponentsModule {}
