import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { SmallstackCheckinComponentsModule } from "@smallstack/checkin-components";
import { SmallstackI18nModule } from "@smallstack/i18n-components";
import { LoaderComponent } from "@smallstack/store-components";
import { SmallstackThemeModule } from "@smallstack/theme-components";
import { AppFooterComponent } from "./app-footer/app-footer.component";
import { AppHeaderComponent } from "./app-header/app-header.component";
import { VisitorEventsComponent } from "./visitor-events/visitor-events.component";

@NgModule({
  imports: [
    CommonModule,
    SmallstackI18nModule,
    MatProgressSpinnerModule,
    SmallstackThemeModule,
    SmallstackCheckinComponentsModule,
    LoaderComponent
  ],
  declarations: [AppFooterComponent, AppHeaderComponent, VisitorEventsComponent],
  exports: [AppFooterComponent, AppHeaderComponent, VisitorEventsComponent]
})
export class SmallstackComponentsModule {}
