@if (backNavigate) {
  <button class="btn btn-primary rounded" (click)="goBack()"> zurück </button>
}
@if (!backNavigate && visitorApp?.logoUrl) {
  <div style="margin-bottom: 30px" class="flex flex-row" [style.justify-content]="visitorApp?.logoAlignment">
    <img
      id="company-logo"
      alt="Company Logo"
      [src]="visitorApp.logoUrl"
      [style.max-width]="visitorApp?.logoWidth"
      [style.max-height]="visitorApp?.logoHeight"
    />
  </div>
}
