import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { CheckinVisitorEventDto } from "@smallstack/axios-api-client";
import { CheckinApp } from "@smallstack/checkin-shared";
import { DataStore } from "@smallstack/common-components";
import { PageableCrudStore } from "@smallstack/store";
import { F_CHECKIN, TYPE_CHECKIN_APPS } from "@smallstack/typesystem";
import { upperCaseFirst } from "@smallstack/utils";

export const checkinEventColors: { [type: string]: string } = {
  in: "#41b359",
  out: "#de6565"
};

@Component({
  selector: "visitor-status-badge",
  templateUrl: "./visitor-status-badge.component.html",
  styleUrls: ["./visitor-status-badge.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisitorStatusBadgeComponent implements OnInit {
  @Input()
  public visitorEvent: CheckinVisitorEventDto;

  @Input()
  public showDate: boolean = true;

  public visitorEventName: string;
  public colors = checkinEventColors;
  public checkinAppStore: PageableCrudStore<CheckinApp>;

  constructor(
    dataStore: DataStore,
    private cdr: ChangeDetectorRef
  ) {
    this.checkinAppStore = dataStore.for(F_CHECKIN, TYPE_CHECKIN_APPS);
  }

  public async ngOnInit(): Promise<void> {
    if (this.visitorEvent) {
      this.visitorEventName = this.visitorEvent.type;
      if (this.visitorEvent.appId) {
        const app = await this.checkinAppStore.get(this.visitorEvent.appId);
        if (app && app.events && app.events.length !== 0) {
          const appEvent = app.events.find((vae) => vae.name === this.visitorEvent.type);
          if (appEvent) this.visitorEventName = appEvent.label;
        }
      }
      // legacy names
      if (this.visitorEventName === "in") this.visitorEventName = "Anmeldung";
      if (this.visitorEventName === "out") this.visitorEventName = "Abmeldung";
      this.visitorEventName = upperCaseFirst(this.visitorEventName);
      this.cdr.markForCheck();
    }
  }
}
